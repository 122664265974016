import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import { Importer, ImporterField } from 'react-csv-importer';

// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer/dist/index.css';
import { useEffect, useState } from 'react';

function validarNumeroBrasileiro(numero) {
  var regex = /^55\d{2}9\d{8}$/;
  return regex.test(numero);
}

function validarCPF(cpf) {
  var regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  return regex.test(cpf);
}

function validarEmail(email) {
  if (email === 'N/A') {
    return true;
  }
  var regex = /^[^@]+@[^@]+\.[^@]+$/;
  return regex.test(email);
}

function validarEmpresa(companyName) {
  return companyName.length > 0;
}

function validateAmount(value) {
  console.log(typeof value);
  console.log({ value });
  // Verifica se é um número, é maior que 0 e menor que 1000
  return typeof value === 'number' && value > 0 && value < 1500;
}

function realParaFloat(valorReais) {
  // Remove o símbolo de R$ e espaços em branco, substitui vírgula por ponto para o formato decimal
  var valorNumerico = valorReais
    .replace('R$', '')
    .replace(/\s/g, '')
    .replace(',', '.');
  return parseFloat(valorNumerico);
}

function calculateErrors(errors) {
  let hasAnyError = false;
  const errorsList = [];

  errors.forEach((entry) => {
    entry.errors.forEach((error) => {
      if (error.value === true) {
        // Supondo que 'true' signifique um erro
        hasAnyError = true;
        errorsList.push({
          document: entry.document,
          fieldWithError: error.field,
        });
      }
    });
  });

  return {
    hasAnyError: hasAnyError,
    errors: errorsList,
  };
}

function somaAmounts(array) {
  return array.reduce((accumulator, currentUser) => {
    return accumulator + currentUser.amount;
  }, 0); // O valor inicial do acumulador é 0
}

function App() {
  const [usersFromCSV, setUsersFromCSV] = useState([]);

  const [users, setUsers] = useState([]);
  const [missingUsers, setMissingUsers] = useState();
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [errorsOnUsers, setErrorsOnUsers] = useState({
    hasAnyError: false,
    errors: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMissingUsers = async () => {
      setLoading(true);

      try {
        const { data } = await axios.post(
          'https://papayas-mass-credits-for-compa-7e3c6d67ca36.herokuapp.com/missing-users',
          {
            usersToVerify: users.map((e) => e.document),
          }
        );
        setMissingUsers(data.missingUsers);
        setStep(4);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (step === 3) {
      try {
        const a = fetchMissingUsers();
      } catch (e) {
        setError(true);
      } finally {
      }
    }
  }, [step]);

  useEffect(() => {
    function filterMissingUsers(users, missingUsers) {
      return users.filter((user) => missingUsers.includes(user.document));
    }
    const addUsers = async (missingUsers) => {
      try {
        setLoading(true);

        const { data } = await axios.post(
          'https://papayas-mass-credits-for-compa-7e3c6d67ca36.herokuapp.com/add-users',
          {
            missingUsers: [...missingUsers],
          },
          {
            headers: {
              Authorization: authCode,
            },
          }
        );
        setMissingUsers(data.missingUsers);
        setStep(6);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (step === 5) {
      try {
        const missingUsersParsed = filterMissingUsers(users, missingUsers);
        addUsers(missingUsersParsed);
      } catch (e) {
        setError(true);
        alert(e.message);
      } finally {
      }
    }
  }, [step]);

  useEffect(() => {
    const executeCharges = async () => {
      try {
        setLoading(true);
        const { data } = await axios.post(
          'https://papayas-mass-credits-for-compa-7e3c6d67ca36.herokuapp.com/execute-recharges',
          {
            usersToCharge: users,
          },
          {
            headers: {
              Authorization: authCode,
            },
          }
        );
        setStep(8);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (step === 7) {
      try {
        const a = executeCharges();
      } catch (e) {
        setError(true);
      } finally {
      }
    }
  }, [step]);

  if (error) {
    return (
      <div>
        <p>Houve algum erro inesperado. Por favor reinicie o processo</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <p>Carregando</p>
      </div>
    );
  }

  if (step === 1) {
    if (errorsOnUsers.hasAnyError) {
      return (
        <div>
          <p>Há erros na tabela. Segue relatório abaixo:</p>
          <br />
          {errorsOnUsers.errors.map((e) => (
            <div>
              <p>
                CPF: {e.document}. Campo com erro: {e.fieldWithError}
              </p>
            </div>
          ))}
        </div>
      );
    }
    return (
      <div>
        <p>Não foram detectados erros na tabela!</p>
        <br />
        <p>
          Confira os dados dos usuários abaixo para ter certeza que tudo está
          correto:
        </p>
        <br />
        ___________________
        <p>
          A lista contém ao todo <b>{users.length} usuários</b>.
        </p>
        <p>
          A soma de todos os créditos será de:{' '}
          <b>
            {somaAmounts(users).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}{' '}
          </b>
          .
        </p>
        ___________________
        <br />
        <p>
          <b>
            Verifique agora o primeiro e o último usuário da lista para validar
            que está tudo certo:
          </b>
        </p>
        <br />
        <p>
          Primeiro da lista - {users[0].name} de CPF {users[0].document}, de
          email {users[0].email}, com celular {users[0].phoneNumber},
          pertencente à empresa {users[0].companyName} terá um crédito de{' '}
          {users[0].amount.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </p>
        <p>
          Último da lista - {users[users.length - 1].name} de CPF{' '}
          {users[users.length - 1].document}, de email{' '}
          {users[users.length - 1].email}, com celular{' '}
          {users[users.length - 1].phoneNumber}, pertencente à empresa{' '}
          {users[users.length - 1].companyName} terá um crédito de{' '}
          {users[0].amount.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </p>
        <br />
        <button onClick={() => setStep(2)}>
          Ok, vamos verificar se todos os usuários estão cadastrados
        </button>
      </div>
    );
  }

  if (step === 2) {
    return (
      <div>
        <p>Insira o código de autenticação para importação:</p>
        <br />
        <input
          name="authCode"
          onChange={(e) => setAuthCode(e.target.value)}
          value={authCode}
        />
        <br />
        <button onClick={() => setStep(3)}>Verificar usuários inseridos</button>
      </div>
    );
  }

  if (step === 4 && Array.isArray(missingUsers)) {
    return (
      <div className="App">
        <header className="App-header">
          {missingUsers.length === 0 ? (
            <div>
              <p>Tudo certo! Não há usuário a serem adicionados.</p>
              <br />
              <button
                onClick={() => {
                  setStep(7);
                }}
              >
                Realizar recargas
              </button>
            </div>
          ) : (
            <div>
              <p>
                Faltam ser criados {missingUsers.length} usuários. São eles:{' '}
              </p>
              <ul>
                {missingUsers.map((user, index) => (
                  <li key={index}>{user}</li>
                ))}
              </ul>
              <button
                onClick={() => {
                  setStep(5);
                }}
              >
                Criar usuários
              </button>
            </div>
          )}
        </header>
      </div>
    );
  }

  if (step === 6) {
    return (
      <div>
        Usuários adicionados!
        <br />
        <button
          onClick={() => {
            setStep(7);
          }}
        >
          Realizar recargas
        </button>
      </div>
    );
  }

  if (step === 8) {
    <div>
      Sucesso! Por favor, confira a lista de transações e{' '}
      <b>recarregue a página para executar uma nova recarga</b>
    </div>;
  }

  return (
    <div className="App">
      <Importer
        dataHandler={async (rows, { startIndex }) => {
          if (startIndex > 0) {
            return;
          }

          setUsersFromCSV(rows);
          // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)

          // required, may be called several times
          // receives a list of parsed objects based on defined fields and user column mapping;
          // (if this callback returns a promise, the widget will wait for it before parsing more data)
          /*           for (row of rows) {
            await myAppMethod(row);
          } */
        }}
        defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
        restartable={false} // optional, lets user choose to upload another file when import is complete
        onStart={({ file, preview, fields, columnFields }) => {
          // optional, invoked when user has mapped columns and started import
          console.log('onStart');
        }}
        onComplete={({ file, preview, fields, columnFields }) => {}}
        onClose={({ file, preview, fields, columnFields }) => {
          // optional, if this is specified the user will see a "Finish" button after import is done,
          // which will call this when clicked
          console.log('onClose');

          console.log({ usersFromCSVOnFinish: usersFromCSV });
          const users = usersFromCSV
            .filter((e) => e.document && e.document !== '')
            .map((e, index) => {
              const user = {
                name: e.name,
                document: e.document.trim(),
                phoneNumber: `${e.phoneNumber.trim()}`,
                email: e.email.trim(),
                companyName: e.companyName.trim(),
                amount: realParaFloat(e.amount),
              };

              return user;
            });

          const errors = users.map((e) => {
            console.log({ user: e });
            const isDocumentValid = validarCPF(e.document);
            const isPhoneNumberValid = validarNumeroBrasileiro(e.phoneNumber);
            const isEmailValid = validarEmail(e.email);
            const iscompanyNameValid = validarEmpresa(e.companyName);

            const isValidAmount = validateAmount(e.amount);

            const error = {
              document: e.document,
              errors: [
                {
                  field: 'document',
                  value: !isDocumentValid,
                },
                {
                  field: 'phoneNumber',
                  value: !isPhoneNumberValid,
                },
                {
                  field: 'email',
                  value: !isEmailValid,
                },
                {
                  field: 'companyName',
                  value: !iscompanyNameValid,
                },
                {
                  field: 'amount',
                  value: !isValidAmount,
                },
              ],
            };
            return error;
          });
          setErrorsOnUsers(calculateErrors(errors));
          setUsers(users);
          setStep(1);
          console.log(users);
        }}

        // CSV options passed directly to PapaParse if specified:
        // delimiter={...}
        // newline={...}
        // quoteChar={...}
        // escapeChar={...}
        // comments={...}
        // skipEmptyLines={...}
        // delimitersToGuess={...}
        // chunkSize={...} // defaults to 10000
        // encoding={...} // defaults to utf-8, see FileReader API
      >
        <ImporterField name="name" label="Name" />
        <ImporterField name="document" label="CPF" optional />
        <ImporterField name="phoneNumber" label="Celular" />
        <ImporterField name="email" label="Email" />
        <ImporterField name="companyName" label="Empresa" />

        <ImporterField name="amount" label="Valor" optional />
      </Importer>
    </div>
  );
}

export default App;
